<template>
  <div v-if="newsById" class="news-full">
    <div class="container">
      <div class="news-full__nav">
        <locale-router-link to="" class="crumbs-link main-page-link">
          {{ $t("home") }}
        </locale-router-link>
        <locale-router-link to="news" class="crumbs-link news-company-link">
          {{ $t("companyNews") }}</locale-router-link
        >
        <a class="crumbs-link news-company-article">
          {{ newsById.attributes.Header }}
        </a>
      </div>

      <div class="header-block">
        <h1 class="title">{{ $t("companyNews") }}</h1>
      </div>

      <div class="news-full__content">
        <div class="news-full__left">
          <h2 class="news-full__title">
            {{ newsById.attributes.Header }}
          </h2>
          <div class="news-full__links">
            <div class="news-full__text">
              <time class="news-full__date">
                {{ getDate(newsById) }}
              </time>
              <p v-if="newsById.attributes.hits" class="news-full__views">
                {{ newsById.attributes.hits }}
              </p>
            </div>
            <div class="news-full__social-links">
              <a
                class="fb"
                :href="getShare('https://www.facebook.com/sharer.php?u=')"
                target="_blank"
              ></a>
              <a
                class="telegram"
                :href="getShare('https://telegram.me/share/url?url=')"
                target="_blank"
              ></a>
            </div>
          </div>
          <img
            :src="getFullNewsImg()"
            alt="Article image"
            width="780"
            height="380"
            class="news-full__article-img"
          />

          <p ref="newsText" class="news-full__text">
            <vue-markdown
              class="stocks-products-full__number-list"
              :source="text"
              :anchorAttributes="{ target: '_blank' }"
              style="white-space: pre-line"
            >
              {{ text }}</vue-markdown
            >
          </p>
        </div>

        <div v-if="blockNews.length" class="news-full__right">
          <h3 class="news-full__read-more">{{ $t("readMore") }}</h3>
          <news-aside-list :items="blockNews" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { mapActions, mapState, mapGetters } from "vuex";
import { actionTypes } from "@/store/modules/news";

export default {
  name: "FullNews",
  data() {
    return {
      // fullNews: null,
      // news: null,
      localMonth: {
        января: "yanvar",
        февраля: "fevral",
        марта: "mart",
        апреля: "aprel",
        мая: "may",
        июня: "iyun",
        июля: "iyul",
        августа: "avgust",
        сентября: "sentyabr",
        октября: "oktyabr",
        ноября: "noyabr",
        декабрь: "dekabr",
      },
    };
  },
  components: {
    VueMarkdown,
    NewsAsideList: () => import("@/components/news/NewsAsideList.vue"),
  },
  computed: {
    ...mapState("newsModule", ["allNews", "newsById"]),
    ...mapGetters("newsModule", ["blockNews"]),
    text() {
      return this.newsById.attributes.text;
    },
  },
  watch: {
    "$route.params.id": {
      deep: true,
      handler(id) {
        this.getNewsById(id);
      },
    },
  },
  mounted() {
    this.getAllNews();
    this.getNewsById(this.$attrs.id);
  },
  methods: {
    ...mapActions("newsModule", {
      getAllNews: actionTypes.loadAllNews,
      getNewsById: actionTypes.loadNews,
    }),
    getShare(item) {
      return item + window.location.href;
    },
    getFullNewsImg() {
      const imgUrl = this.newsById?.attributes?.image?.data?.attributes?.url;
      return process.env.VUE_APP_API_URL + imgUrl;
    },
    getDate(item) {
      const dateFromStr = new Date(item.attributes.publishedAt);
      let dateRU = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateFromStr);
      let date = dateRU.split(" ");
      let dateUZ = date[0] + " " + this.localMonth[date[1]] + " " + date[2];

      if (this.$i18n.locale === "uz") {
        return dateUZ;
      } else {
        return dateRU;
      }
    },
  },
};
</script>

<style scoped></style>
